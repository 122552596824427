import translate from "../../i18n/translate";
import ECO_F10_01 from "../../images/ECO_F10_01.png";
import Episode1 from "../../images/firstPodcastImage.png";

export const episodes = [
  {
    id: "03",
    podcast_id: "03",
    title: translate("third-podcast-title"),
    content: translate("third-podcast-description"),
    language: translate("third-podcast-language"),
    attendees: translate("third-podcast-attendees"),
    host: "Marc Schröter (globaldatanet)",
    guestOne: "Larissa Becka (Amazon Web Services)",
    guestTwo: "",
    guestThree: "",
    guestFour: "",
    logo: Episode1,
    media_url:
      "https://mcdn.podbean.com/mf/download/923hhz/Intelligente_Dokumentenverarbeitung_mit_KI_mp38g0j7.mp3",
    backgroundimagedark: true,
    player_url:
      "https://www.podbean.com/player-v2/?share=1&download=1&rtl=0&fonts=Arial&skin=1b1b1b&font-color=ffffff&btn-skin=1b1b1b&multiple_size=315&square_size=300&order=episodic&filter=all&limit=10&season=all&tag=all&logo_link=none&i=w7n6u-1256528-pb",
    permalink_url:
      "https://globaldatanet.podbean.com/e/intelligente-dokumentenverarbeitung-mit-ki/",
    publish_time: 1655816350,
    status: "publish",
    type: "public",
    duration: 1246,
    season_number: 0,
    episode_number: 1,
    apple_episode_type: "full",
    object: "Episode",
  },
  {
    id: "02",
    podcast_id: "02",
    title: translate("second-podcast-title"),
    content: translate("second-podcast-description"),
    language: translate("second-podcast-language"),
    attendees: translate("second-podcast-attendees"),
    host: "Sidonie Krug & Sven Oswald",
    guestOne: "Marc Schröter (globaldatanet)",
    guestTwo: "Felix Höger (EuroCloud)",
    guestThree: "Silke Kanes (Scopevisio AG)",
    guestFour: "Henrik Hasenkamp (gridscale)",
    logo: ECO_F10_01,
    media_url: "https://4ew3sj.podcaster.de/ecoverband/media/ECO_F10_01.mp3",
    backgroundimagedark: false,
    player_url:
      "https://open.spotify.com/episode/2NqPV9VdiABuk5GeAF2PDA?go=1&sp_cid=42dd073751c31efec15d760e50523f91&utm_source=embed_player_p&utm_medium=desktop&nd=1",
    permalink_url:
      "https://open.spotify.com/episode/2NqPV9VdiABuk5GeAF2PDA?go=1&sp_cid=42dd073751c31efec15d760e50523f91&utm_source=embed_player_p&utm_medium=desktop&nd=1",
    publish_time: 1655816350,
    status: "publish",
    type: "public",
    duration: 1246,
    season_number: 0,
    episode_number: 1,
    apple_episode_type: "full",
    object: "Episode",
  },
  {
    id: "W7N6U1256528",
    podcast_id: "0dyKwmIGdsld",
    title: translate("first-podcast-title"),
    content: translate("first-podcast-description"),
    language: translate("first-podcast-language"),
    attendees: translate("first-podcast-attendees"),
    host: "Marc Schröter (globaldatanet)",
    guestOne: "Janek Luenstedt (Amazon Web Services)",
    guestTwo: "",
    guestThree: "",
    guestFour: "",
    logo: Episode1,
    backgroundimagedark: true,
    media_url:
      "https://mcdn.podbean.com/mf/web/xutsk7/001_-_Digitalisierung_in_der_Logistik_-_Janek_Luenstedt_von_AWS_high66tkk.mp3",
    player_url:
      "https://www.podbean.com/player-v2/?share=1&download=1&rtl=0&fonts=Arial&skin=1b1b1b&font-color=ffffff&btn-skin=1b1b1b&multiple_size=315&square_size=300&order=episodic&filter=all&limit=10&season=all&tag=all&logo_link=none&i=w7n6u-1256528-pb",
    permalink_url:
      "https://globaldatanet.podbean.com/e/001-digitalisierung-in-der-logistik/",
    publish_time: 1655816350,
    status: "publish",
    type: "public",
    duration: 1246,
    season_number: 0,
    episode_number: 1,
    apple_episode_type: "full",
    object: "Episode",
  },
];
