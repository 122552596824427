import React from "react";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";

import podcastHero from "../../images/podcastHero.webp";
import { UseWindowSize } from "../hooks/UseWindowSize";
import translate from "../../i18n/translate";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import TwoColumnPodcast from "../Shared/TwoColumPodcast/TwoColumnPodcast";
import { episodes } from "./podcast.data";

const PodcastMain = () => {
  const width = UseWindowSize();

  return (
    <div>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={"Serverless World Podcast"}
          description={translate("podcast-description")}
          homeBackground={podcastHero}
          buttonText={translate("podcast-subscribe")}
          date=""
          externalLink="https://globaldatanet.podbean.com/"
          alt="Partners"
        />
      ) : (
        <HeroBanner
          heading={"Serverless World Podcast"}
          description={translate("podcast-description")}
          homeBackground={podcastHero}
          buttonText={translate("podcast-subscribe")}
          date=""
          externalLink="https://globaldatanet.podbean.com/"
        />
      )}

      <InnerWrapper>
        <TwoColumnPodcast order2={1} order1={2} episodes={episodes} />
      </InnerWrapper>
    </div>
  );
};

export default PodcastMain;
