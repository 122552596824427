import React, { FC } from "react";
import Layout from "../components/Layout/Layout";
import PodcastMain from "../components/PodcastUiComponents/PodcastMain";
import { SEO } from "../components/seo";
import SeoImg from "../images/serverless-world-podcast-header.jpg";

const Podcast: FC = () => (
  <Layout location="/partners">
    <SEO
      title="Serverless World Podcast"
      description="The Serverless World Podcast - Listen to experts talking about modern challenges and opportunities within the cloud and its various potentials for your business."
      type="website"
      image={SeoImg}
    />
    <PodcastMain />
  </Layout>
);

export default Podcast;
