import React, { FC } from "react";
import translate from "../../../i18n/translate";
import langIcon from "../../../images/langIcon.svg";
import guestIcon from "../../../images/guestIcon.svg";

type Props = {
  description: string;
  linkToPodcast: string;
  language: string;
  attendees: string;
  host: string;
  guestOne: string;
  guestTwo: string;
  guestThree: string;
  guestFour: string;
};

const TwoColumnPodcastListItem: FC<Props> = ({
  description,
  language,
  attendees,
  linkToPodcast,
  host,
  guestOne,
  guestTwo,
  guestThree,
  guestFour,
}) => {
  return (
    <div style={{ color: "white", marginTop: "5px" }}>
      <span>
        {description} <br />{" "}
      </span>
      <span style={{ display: "flex", alignItems: "center" }}>
        {" "}
        <img
          src={langIcon}
          alt="language"
          style={{ height: "24px", marginTop: "15px", marginRight: "15px" }}
        />
        <br /> {language} <br />{" "}
      </span>
      <span style={{ display: "flex", alignItems: "center" }}>
        <img
          src={guestIcon}
          alt="language"
          style={{ height: "20px", marginTop: "15px", marginRight: "10px" }}
        />
        <br /> {host} <br />
      </span>
      <span style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "43px" }}></div>
        <br /> {guestOne} <br />
      </span>
      {guestTwo.length ? (
        <span style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "43px" }}></div>
          <br /> {guestTwo} <br />
        </span>
      ) : null}
      {guestThree.length ? (
        <span style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "43px" }}></div>
          <br /> {guestThree} <br />
        </span>
      ) : null}
      {guestFour.length ? (
        <span style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "43px" }}></div>
          <br /> {guestFour} <br />
        </span>
      ) : null}
      <div style={{ marginTop: "5px" }}>
        <span>
          {" "}
          <br />
          {translate("view-podcast")}
          <a
            href={linkToPodcast}
            target="_blank"
            style={{ color: "#00ecbd" }}
            rel="noreferrer"
          >
            {" "}
            App
          </a>
        </span>
      </div>
    </div>
  );
};

export default TwoColumnPodcastListItem;
