// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "TwoColumnPodcast-module--aboutMainCircleBlurBlue--y15Xl";
export var bHeading = "TwoColumnPodcast-module--bHeading--e-xCp";
export var development = "TwoColumnPodcast-module--development--NjtuB";
export var gdnPromotePic = "TwoColumnPodcast-module--gdnPromotePic--h5spZ";
export var head = "TwoColumnPodcast-module--head--GgUvr";
export var heroBackgroundCircleLeft = "TwoColumnPodcast-module--heroBackgroundCircleLeft--m5h0u";
export var heroBackgroundCircleLeftStory = "TwoColumnPodcast-module--heroBackgroundCircleLeftStory--Yf90x";
export var heroBackgroundCircleLeftStorySecond = "TwoColumnPodcast-module--heroBackgroundCircleLeftStorySecond--4e8P9";
export var heroBackgroundInnerCircleLeft = "TwoColumnPodcast-module--heroBackgroundInnerCircleLeft--vZknh";
export var heroBackgroundInnerCircleLeftSecond = "TwoColumnPodcast-module--heroBackgroundInnerCircleLeftSecond---AQxb";
export var knowUs = "TwoColumnPodcast-module--knowUs--VLF1G";
export var knowUsHeading = "TwoColumnPodcast-module--knowUsHeading--fRvvK";
export var knowUsImage = "TwoColumnPodcast-module--knowUsImage--znrHP";
export var knowUsImageDiv = "TwoColumnPodcast-module--knowUsImageDiv--b5Z4V";
export var knowUsOurStory = "TwoColumnPodcast-module--knowUsOurStory--jUBKN";
export var knowUsOurStoryText = "TwoColumnPodcast-module--knowUsOurStoryText--+M9Bu";
export var knowUsText = "TwoColumnPodcast-module--knowUsText--R2HK6";
export var ourStoryDescPara = "TwoColumnPodcast-module--ourStoryDescPara--nQ34B";
export var ourStoryImg = "TwoColumnPodcast-module--ourStoryImg--FCyJF";
export var ourStoryImgDiv = "TwoColumnPodcast-module--ourStoryImgDiv--UbVZg";
export var ourStoryText = "TwoColumnPodcast-module--ourStoryText--Ph9vy";
export var storyPromotePic = "TwoColumnPodcast-module--storyPromotePic--jCL8c";
export var storyPromotePicSecond = "TwoColumnPodcast-module--storyPromotePicSecond--Imy+U";
export var topHeading = "TwoColumnPodcast-module--topHeading--cZ27g";